<template>
    <div class="relative">
        <swiper class="swiper" :options="swiperOption">
            <swiper-slide
                v-for="company in swiperData"
                :key="company.companyId"
            >
                <router-link
                    :to="`/tradeshow/exhibitor-details/${company.companyId}`"
                    class="w-full  xl:p-4"
                    ><img
                        :src="getLogoImage(company)"
                        :alt="company.companyName"
                        class="w-full"
                /></router-link>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <!-- <div class="swiper-button-prev text-accent"></div>
        <div class="swiper-button-next text-accent"></div> -->
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/css/swiper.css";

export default {
    name: "MultiRowSwiper",
    title: "Multiple Row slider",
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        swiperData: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 1,
                slidesPerColumn: 2,
                spaceBetween: 20,
                mousewheel: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            }
        };
    },
    methods: {
        getLogoImage(exhibitor) {
            let returnPath = exhibitor.imageLogo || exhibitor.images?.logo;
            if (!returnPath) {
                return (returnPath = `/bg/${this.pageConfig.defaultLogo}`);
            } else {
                return returnPath;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../styles/components/verticalcarousel";
@import "../../styles/setup/mixins";

// .swiper-button-next,
// .swiper-button-prev {
//     transform: rotate(90deg);
//     transform-origin: left center;
// }
</style>
