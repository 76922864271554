







































































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import Spinners from "@/components/utilities/Spinners.vue";
import NotFound from "@/views/NotFound.vue";
import eventHub from "@/event-hub";
import store from "@/store";

import VerticalCarousel from "@/components/shared/VerticalCarousel.vue";
import MultiRowSwiper from "@/components/shared/MultiRowSwiper.vue";
import VideoComponent from "@/components/shared/VideoComponent.vue";
import Home from "@/components/home/DefaultHomePage.vue";
import AdSlot from "@/components/shared/AdSlot.vue";
import ActionIcon from "@/components/home/section/ActionIcon.vue";

import featuredContentVuexModule from "@/store/vuex-modules/featuredContent";
import featuredVideoVuexModule from "@/store/vuex-modules/getFeaturedVideo";
import featuredAttendeeVuexModule from "@/store/vuex-modules/getFeaturedAttendees";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";

import { AttendeeSwimlaneObject, FeaturedContentObject, FeaturedVideoObject, SessionDataArray } from "@/types/interfaces";
import { TradeshowCompanyObject } from "@/types/interfaces";

const featuredContentStore = getModule(featuredContentVuexModule);
const featuredVideoStore = getModule(featuredVideoVuexModule);
const featuredAttendeeStore = getModule(featuredAttendeeVuexModule);
const tradeshowStore = getModule(tradeshowVuexModule);
const sessionStore = getModule(sessionVuexModule);


export default  Vue.extend ({
    data() {
        return {
            
            publicPath : process.env.BASE_URL,
            isLoading : true,
            isLoadingVideo : true,
            hasLobbyData : false,
            isAttendeeLoading : true,
            loadVideo : false,
            loadFetauredContent : false,
            featuredSession: [] as any,
            isFeaturedDataLoading : true,
            isLoaded : false,
            isSponsorLoading : true,
        }
    },
    components: {
        Home,
        Spinners,
        VideoComponent,
        VerticalCarousel,
        MultiRowSwiper,
        AdSlot,
        ActionIcon,
        NotFound,
        LobbyContent: () => {
            const options = store.getters.getPageOptions("home")
                .componentVariance;

            let componentToUse = "";

            options.forEach((item: any) => {
                if (item.isActive) {
                    componentToUse = item.component;
                }
            });

            return import(`./svg/${componentToUse}`);
        },
        AttendeeSwimlane: () => {
            const returnResult = store.getters.getPageOptions("home").section
                ?.attendeeSwimlane?.componentToLoad;
            return import(`@/components/attendees/swimlanes/${returnResult}`);
        }
    },
    directives: {
        addSvg: {
            inserted() {
                eventHub.$emit("HomeLobbySvgInserted");
            }
        }
    },
    computed: {

        notProduction(): boolean {
            return Boolean("production" !== process.env.NODE_ENV);
        },
    
        pageOptions(): any {
            return this.$store.getters.getPageOptions("home") || {};
        },
    
        featuredContent(): FeaturedContentObject[] {
            return featuredContentStore.featuredContent;
        },
    
        sessionData(): SessionDataArray {
            return sessionStore.sessionPromos ? sessionStore.sessionPromos : [];
        },
    
        featuredVideo(): FeaturedVideoObject {
          return featuredVideoStore.featuredVideo;
        },
        attendeeSwimlane() {
          return featuredAttendeeStore.featuredAttendeeSwimlanes || [];
        },
        attendeeSwimlanes(): Record<string, string> | never[]{
            return this.attendeePageOptions.swimLanes
                ? this.attendeePageOptions.swimLanes
                : [];
        },
    
        attendeePageOptions(): Record<string, Record<string, string>> {
            return this.$store.getters.getPageOptions("attendees");
        },
    
        sponsorData(): TradeshowCompanyObject[] {
            const companies = tradeshowStore.allTradeshowCompanies;
    
            return companies.sort(
                (cur: TradeshowCompanyObject, nxt: TradeshowCompanyObject) => {
                    const curName = cur.companyName
                        ? cur.companyName
                        : cur.name
                        ? cur.name
                        : "";
    
                    const nextName = nxt.companyName
                        ? nxt.companyName
                        : nxt.name
                        ? nxt.name
                        : "";
    
                    return curName.localeCompare(nextName);
                }
            );
        },
    
        homeLabel(): string {
            return this.$store.getters.homeLabel;
        }
    },
    methods: {
        logoImageSrc(str: string) {
            return `${this.publicPath}staticSponsorLogo/${str}`;
        },
        checkBtn(btnID: string): boolean {
            return this.pageOptions.section.svgButtons.find((id: any) => {
                if (id.id === btnID) {
                    return id.useButton;
                }
            });
        },
        handleRoomItemsInit() {
            const elementWithLink = document.querySelectorAll(".with-link");
            console.log(elementWithLink);
    
            [...elementWithLink].forEach((item: any) => {
                item.classList.add("cursor-pointer");
    
                switch (item.id) {
                    case "help-page":
                        item.addEventListener("click", () => {
                            this.$router.push("/help");
                        });
                        break;
                    case "attendeebtn1":
                        if (this.checkBtn("attendeebtn1")) {
                            item.classList.remove("hidden");
                        }
    
                        item.addEventListener("click", () => {
                            this.$router.push("/attendees");
                        });
                        break;
                    case "Guestbook":
                        if (this.checkBtn("Guestbook")) {
                            item.classList.remove("hidden");
                        }
                        item.addEventListener("click", () => {
                            window.open(
                                "https://fact.kudoboard.com/boards/jn776iLS/25thanniversary",
                                "_blank"
                            );
                            const opener: any = window.open();
                            opener.opener = null;
                        });
                        break;
                    case "VC-tip":
                        if (this.checkBtn("VC-tip")) {
                            item.classList.remove("hidden");
                        }
                        item.addEventListener("click", () => {
                            this.$router.push("/sessions/on-demand-04");
                        });
                        break;
                    case "messageCenter":
                        item.addEventListener("click", () => {
                            this.$router.push("/message-center");
                        });
                        break;
                    case "schedule":
                        if (this.checkBtn("schedule")) {
                            item.classList.remove("hidden");
                        }
                        item.addEventListener("click", () => {
                            this.$router.push("/schedule");
                        });
                        break;
                    case "attendeebtn2":
                        if (this.checkBtn("attendeebtn2")) {
                            item.classList.remove("hidden");
                        }
    
                        item.addEventListener("click", () => {
                            this.$router.push("/attendees");
                        });
                        break;
                }
            });
    
            this.loadVideo = true;
            this.isLoaded = true;
        }
    },

    created() {
        eventHub.$on("HomeLobbySvgInserted", this.handleRoomItemsInit);
        this.hasLobbyData = true;
        this.isLoading = false;
        const newPromises = [
            featuredContentStore.getFeaturedContent(),
            featuredVideoStore.getFeaturedVideo(),
            tradeshowStore.getTradeshowCompanies(),
            sessionStore.getSessionData({
                isDemoData: false
            })
        ];

        Promise.allSettled(newPromises)
            .then(() => {
                this.featuredSession = [...this.featuredContent];
                this.isLoadingVideo = false;
            })
            .finally(() => {
                this.loadFetauredContent = true;
                this.isFeaturedDataLoading = false;
                this.isSponsorLoading = false;
            });

        featuredAttendeeStore
            .getFeaturedAttendees(this.attendeeSwimlanes as AttendeeSwimlaneObject[])
            .finally(() => (this.isAttendeeLoading = false));
    },
    beforeDestroy() {
        eventHub.$off("HomeLobbySvgInserted", this.handleRoomItemsInit);
    },
})
