<template>
    <div class="relative">
        <div
            v-if="!featuredData.length"
            class="flex justify-center items-center"
        >
            <p class="text-white italic">
                {{ `No DATA for ${textHeader} to load` }}
            </p>
        </div>
        <template v-else>
            <swiper class="swiper h-full" :options="swiperOption">
                <swiper-slide
                    v-for="data in featuredData"
                    :key="data.itemId"
                    class="overflow-hidden relative"
                >
                    <a
                        v-if="isValidURL(data.url)"
                        :href="data.url"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="block absolute top-0 left-0 h-full w-full"
                        ><img
                            :src="data.image"
                            :alt="data.title"
                            class="block absolute top-0 left-0 h-full w-full object-contain"
                    /></a>
                    <router-link
                        v-else
                        :to="data.url"
                        class="block absolute top-0 left-0 h-full w-full"
                        ><img
                            :src="data.image"
                            :alt="data.title"
                            class="block absolute top-0 left-0 h-full w-full object-contain"
                    /></router-link>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
            <div
                v-if="featuredData.length"
                class="swiper-button-prev text-accent"
                :style="[
                    getNavigationElCustomStyle.navigationEl,
                    getNavigationElCustomStyle.prevEl
                ]"
            ></div>
            <div
                v-if="featuredData.length"
                class="swiper-button-next text-accent"
                :style="[
                    getNavigationElCustomStyle.navigationEl,
                    getNavigationElCustomStyle.nextEl
                ]"
            ></div>
        </template>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/css/swiper.css";

export default {
    name: "VerticalCarousel",
    title: "Vertical slider",
    components: {
        Swiper,
        SwiperSlide
    },
    props: {
        featuredData: {
            type: Array,
            default() {
                return [];
            }
        },
        textHeader: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            swiperOption: {
                direction: "vertical",
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    },
                    1440: {
                        slidesPerView: 4,
                        spaceBetween: 10
                    },
                    2260: {
                        slidesPerView: 4,
                        spaceBetween: 20
                    }
                },
                spaceBetween: 20,
                mousewheel: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            }
        };
    },
    updated() {
        Array.from(document.getElementsByClassName("swiper-slide")).forEach(
            (item) => {
                item.style.removeProperty("height");
            }
        );
    },
    methods: {
        isValidURL(url) {
            const chckUrl = url.match(
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
            );
            return chckUrl !== null;
        }
    },
    computed: {
        getNavigationElCustomStyle() {
            return (
                this.$store.getters.getPageOptions("swiper").navigation || {}
            );
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../styles/components/verticalcarousel";
@import "../../styles/setup/mixins";

.swiper-button-next,
.swiper-button-prev {
    transform: rotate(90deg);
    transform-origin: left center;
}
</style>
