
























import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Vue  from "vue";

export default Vue.extend({
    components: { PageTitleHeader }
})
